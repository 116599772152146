import { get, post, remove, update } from '~/services/http-requests'

const _BS_ANALYTICS = 'analytics'

const organizeProperties = (arr: any) => ({
    by_days: { last_7_days: arr.by_days.slice(arr.by_days.length - 7), last_30_days: arr.by_days },
    by_months: arr.by_months
})

// TOTALS
export function fetchTotalSales(): Promise<IAnalyticsDataTotal> {
    return get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/total_sales/` })
}

export function fetchTotalBeatSold(): Promise<IAnalyticsDataTotal> {
    return get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/total_beat_sold/` })
}

export function fetchTotalBeatDemoDownloads(): Promise<IAnalyticsDataTotal> {
    return get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/total_beat_demo_downloads/` })
}

export function fetchTotalBeatPlays(): Promise<IAnalyticsDataTotal> {
    return get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/total_beat_plays/` })
}

export function fetchTotalPageVisits(): Promise<IAnalyticsDataTotal> {
    return get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/total_page_visits/` })
}

// DETAILS
export async function fetchDetailedSales(): Promise<IAnalyticsDataDetailed> {
    const data = await get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/detailed_sales/` })
    return organizeProperties(data)
}

export async function fetchDetailedBeatSold(): Promise<IAnalyticsDataDetailed> {
    const data = await get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/detailed_beat_sold/` })
    return organizeProperties(data)
}

export async function fetchDetailedBeatPlays(): Promise<IAnalyticsDataDetailed> {
    const data = await get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/detailed_beat_plays/` })
    return organizeProperties(data)
}

export async function fetchDetailedBeatDemoDownloads(): Promise<IAnalyticsDataDetailed> {
    const data = await get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/detailed_beat_demo_downloads/` })
    return organizeProperties(data)
}

export async function fetchDetailedPageVisits(): Promise<IAnalyticsDataDetailed> {
    const data = await get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/detailed_page_visits/` })
    return organizeProperties(data)
}

export function fetchTopTenBeats(): Promise<any> {
    return get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/top_ten_beats/plays/last_7_days/` })
}

export function fetchSingleBeatPlays(beatId: IBeat['id']): Promise<number> {
    return get({ baseProperty: _BS_ANALYTICS, fullUrl: `${_BS_ANALYTICS}/number_of_plays/${beatId}/` })
}
