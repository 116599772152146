/* charts style options */
import { ChartOptions } from 'chart.js'

export const getDefaultChartOptions: () => ChartOptions = () => ({
	// elements: { line: { tension: 0 } }, // disables bezier curves
	/* set actual stilying */
	layout: { padding: 6 },
	scales: {
		x: {
			afterTickToLabelConversion: (data: any) => data,
			grid: { display: false, tickMarkLength: 15 },
			ticks: { fontStyle: 700, maxRotation: 0, minRotation: 0 },
			min: 0
		},
		y: {
			grid: { color: '#EDEDED', drawBorder: false, tickMarkLength: 0 },
			ticks: {
				padding: 10,
				fontStyle: 700,
				beginAtZero: true,
				callback: (value: any) => {
					// if name is longer than 6 letters, truncate, remove white-spaces and add '...'
					if (value.length > 9 && typeof value !== 'number') {
						return value.substr(0, 9).replace(/((\s*\S+)*)\s*/, '$1') + '...'
					} else if (typeof value === 'number') return Math.floor(value)
					return value
				}
			},
			min: 0
		}
	},
	tooltips: {
		// mode: 'x',
		callbacks: {
			title: (tooltipItem: any, data: any) => data.labels[tooltipItem[0].index],
			// label: (tooltipItem, data) => `${data['datasets'][0]['data'][tooltipItem['index']]}`
			label: (tooltipItem: any, data: any) => data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
		},
		bodyFontSize: 14,
		displayColors: false,
		fontStyle: 700
	},
	plugins: {
		legend: { display: false }
	}
})
