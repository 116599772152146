










import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'

@Component
export default class TileAnalytics extends Vue {
    @Prop({ type: String }) title: string
    @Prop({ type: Number }) data: number
    @Prop({ type: String }) icon: string
    @Prop({ type: String }) color: string
    @Prop({ type: Boolean, default: true }) showDateRange: boolean

    get daysRange() {
        return this.title === 'Beat Plays' ? 'last 7 days' : 'last 30 days'
    }
}
