













import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { UserStore } from '@/store/user'
import { fetchBeats } from '#/api/beat'

@Component
export default class TaskProgress extends Vue {
	@UserStore.State('authenticatedUser') authenticatedUser: IUserProducer

	userHasBeats = false

	get tasks() {
		// if (!state.authenticatedUser) return false
		const user = this.authenticatedUser
		const tasks = []
		tasks.push(
			{
				description: 'Create Account',
				isDone: true,
				orderNumber: 1,
				fullPath: ''
			},
			{
				description: 'Upload Logo',
				isDone: user && user.image_logo_url ? true : false,
				orderNumber: 2,
				fullPath: '/settings'
			},
			{
				description: 'Upload Background',
				isDone: user && user.image_background_url ? true : false,
				orderNumber: 3,
				fullPath: '/settings#producer-page'
			},
			{
				description: 'Connect PayPal',
				isDone: user && (user.paypal_email) ? true : false,
				orderNumber: 4,
				fullPath: '/preferences'
			},
			{
				description: 'Upload Beats',
				isDone: this.userHasBeats,
				orderNumber: 5,
				fullPath: '/beats/upload?type=beats'
			}
		)
		// set true values first
		// @ts-ignore
		tasks.sort((x, y) => (y.isDone === x.isDone ? x.orderNumber - y.orderNumber : y.isDone - x.isDone))
		return tasks
	}

	get isAllCompleted() {
		const checkIfAllTrue = (tasks: any) => {
			for (const i in tasks) {
				if (tasks[i].isDone === false) return false
			}
			return true
		}
		return checkIfAllTrue(this.tasks)
	}

	get taskProgressBarWidth() {
		let width = 0
		// add 20 for each completed task
		this.tasks.forEach(item => (item.isDone ? (width += 20) : (width += 0)))
		// if only 1 task is completed, width should be 10%
		width = width === 20 ? width - 10 : width + 10
		// if all tasks are completed width will be 110%, so we reduce to 100
		width = width > 100 ? 100 : width
		return width
	}

	async fetchBeats() {
		const { results } = await fetchBeats({ limit: 1, offset: 0 })
		this.userHasBeats = results.length ? true : false
	}

	created() {
		this.fetchBeats()
	}
}
