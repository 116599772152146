import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export class ChartComputed extends Vue {

    get last7Days() {
        const last7Days = []
        for (let x = 6; x >= 0; x--) {
            last7Days.push(
                Vue.moment()
                    .subtract(x, 'days')
                    .format('DD MMM YYYY')
            )
        }
        return last7Days
    }

    get last30Days() {
        const last30Days = []
        for (let x = 29; x >= 0; x--) {
            last30Days.push(
                Vue.moment()
                    .subtract(x, 'days')
                    .format('DD MMM YYYY')
            )
        }
        return last30Days
    }

    get last12Months() {
        const last12Months = []
        for (let x = 11; x >= 0; x--) {
            last12Months.push(
                Vue.moment()
                    .subtract(x, 'months')
                    .format('MMM YYYY')
            )
        }
        return last12Months
    }
}
