import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Chart, Scale, Tick } from 'chart.js'

@Component
export class ChartMethods extends Vue {
	chart: Chart | null = null

	getChartId(): string {
		return null
	}

	gradientColor({ colorRgb, hover }: { colorRgb: string; hover: boolean }) {
		const ctx = (document.getElementById(this.getChartId()) as HTMLCanvasElement).getContext('2d')!
		const gradientColor = ctx.createLinearGradient(0, 0, 0, 450)
		gradientColor.addColorStop(0, `rgba(${colorRgb},.${hover ? 6 : 4})`)
		gradientColor.addColorStop(0.5, `rgba(${colorRgb},.${hover ? 4 : 2})`)
		gradientColor.addColorStop(1, `rgba(${colorRgb},0)`)
		return gradientColor
	}

	generateChartStyling({ hex, colorRgb }: { hex: string; colorRgb: string }) {
		return {
			borderWidth: 2,
			borderColor: hex,
			backgroundColor: this.gradientColor({ colorRgb, hover: false }),
			hoverBackgroundColor: this.gradientColor({ colorRgb, hover: true }),
			pointBackgroundColor: 'white',
			pointHoverBackgroundColor: hex,
			pointBorderColor: hex,
			pointRadius: 4,
			tension: 0.5
		}
	}

	dateAfterTickToLabelConversion(scale: Scale) {
		return scale.ticks.forEach((tick: Tick, i: any) => {
			const label = tick.label as string
			scale.ticks[i].label = label.split(' ').slice(0, 2).join(' ')
		})
	}
}
