



import { Component, Mixins, Prop } from 'vue-property-decorator'
import { getDefaultChartOptions } from '#/const/charts'
import { ChartMethods } from '#/mixins/chart-methods'
import { ChartComputed } from '#/mixins/chart-computed'
import { analyticsColors } from '#/const/analytics-colors'
import { Chart } from 'chart.js'

@Component
export default class BeatDownloads extends Mixins(ChartMethods, ChartComputed) {
	@Prop() chartPoints: any[]

	getChartId() {
		return 'beatDownloadsChart'
	}

	mounted() {
		const chartOptions = getDefaultChartOptions()
		chartOptions.scales.x.afterTickToLabelConversion = this.dateAfterTickToLabelConversion
		// @ts-ignore
		chartOptions.tooltips.callbacks.label = (tooltipItem, data) => {
			const quantity = data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']]
			return `${quantity} beat${quantity > 1 ? 's' : ''} downloaded`
		}
		const ctx = (document.getElementById(this.getChartId()) as HTMLCanvasElement).getContext('2d')!

		if (!this.chart)
			this.chart = new Chart(ctx, {
				type: 'line',
				// @ts-ignore
				options: chartOptions,
				data: {
					datasets: []
				}
			})

		this.chart.data = {
			labels: this.last30Days,
			datasets: [
				{
					spanGaps: true,
					data: this.chartPoints,
					...this.generateChartStyling({
						hex: analyticsColors.demoDownloads.hex,
						colorRgb: analyticsColors.demoDownloads.rgb
					})
				}
			]
		}
		this.chart.update()
	}
}
