import { get, update, IApiResponseExtended, post } from '~/services/http-requests'
import { convertToGoodQuotes } from '~/utils/convertToGoodQuotes'
import { ILimitOffsetParams } from '~/interfaces/interface-utils'

const _BS_BEATS = 'beats'
const _BS_PRIVATE_BEATS = 'private_beats'

function cleanBeat(beat: Partial<IBeatProducer>) {
    if (beat.genre && beat.genre.id) beat.genre_id = beat.genre.id
    if (beat.artist_type && beat.artist_type.id) beat.artist_type_id = beat.artist_type.id
    if (beat.main_instrument && beat.main_instrument.id) beat.main_instrument_id = beat.main_instrument.id
    if (beat.name) beat.name = convertToGoodQuotes(beat.name)
    return beat
}

/*
 **** GET ****
 */
async function fetchSingleBeat(beatId: IBeatProducer['id']): Promise<IBeatProducer> {
    const [first] = await get({ baseProperty: _BS_BEATS, fullUrl: `${_BS_BEATS}.json?id=${beatId}` })
    return first
}

function fetchBeats({ limit = null, offset = null }: ILimitOffsetParams): Promise<IApiResponseExtended<IBeatProducer[]>> {
    return get({ baseProperty: _BS_BEATS, fullUrl: `${_BS_BEATS}.json?limit=${limit}&offset=${offset}` })
}

function fetchVaultBeats(): Promise<IBeatProducer[]> {
    return get({ baseProperty: _BS_PRIVATE_BEATS })
}

function fetchFilteredBeats(value: string): Promise<IBeatProducer[]> {
    return get({ baseProperty: _BS_BEATS, fullUrl: `${_BS_BEATS}.json?name__icontains=${value}` })
}

function fetchAllBeatsLight(): Promise<IBeatLight[]> {
    return get({ baseProperty: _BS_BEATS, fullUrl: `${_BS_BEATS}.json?fields=id,name,order_position` })
}

function fetchAllBeatsLightWithPic(): Promise<IBeatLight[]> {
    return get({ baseProperty: _BS_BEATS, fullUrl: `${_BS_BEATS}.json?fields=id,name,image_url` })
}

function fetchUploadedBeatsData(): Promise<IUploadedBeatsData> {
    return get({ baseProperty: '', fullUrl: `uploaded_beats_data.json` })
}

/*
 **** POST ****
 */
async function createBeat(beat: IBeatProducer): Promise<IBeatProducer> {
    const beatResponse: IBeatProducer = await post({ baseProperty: _BS_BEATS, dispatchParams: cleanBeat(beat) })
    await post({ fullUrl: `transloadit/add_watermark_to_file/${beatResponse.id}/`, isFreeRequest: true })
    return beatResponse
}

/*
 **** PATCH ****
 */
function updateBeat(beat: Partial<IBeatProducer>) {
    return update({ baseProperty: _BS_BEATS, fullUrl: `${_BS_BEATS}/${beat.id}/`, dispatchParams: cleanBeat(beat) })
}

export {
    fetchSingleBeat,
    fetchBeats,
    fetchVaultBeats,
    fetchFilteredBeats,
    fetchAllBeatsLight,
    fetchAllBeatsLightWithPic,
    fetchUploadedBeatsData,
    createBeat,
    updateBeat
}
