/* charts style options */
export const analyticsColors = {
	earnings: {
		hex: '#26E87F',
		rgb: '38, 232, 127'
	},
	beatSold: {
		hex: '#F5A623',
		rgb: '245, 166, 35'
	},
	beatUpload: {
		hex: '#F5A623',
		rgb: '245, 166, 35'
	},
	demoDownloads: {
		hex: '#2196F3',
		rgb: '32, 152, 255'
	},
	beatPlays: {
		hex: '#9013FE',
		rgb: '144, 19, 254'
	},
	pageViews: {
		hex: '#e61cbd',
		rgb: '230, 28, 189'
	},
	followers: {
		hex: '#fd3636',
		rgb: '253, 54, 54'
	},
	signups: {
		hex: '#fd3636',
		rgb: '253, 54, 54'
	}
}
