
































































import { Component, Mixins } from 'vue-property-decorator'

import { UserStore } from '#/store/user'
import { analyticsColors } from '#/const/analytics-colors'
import { Dropdowns } from '~/mixins/dropdowns'

import VLoadSpinner from '~/components/VLoadSpinner.vue'
import SaleEarnings from '#/components/charts/SaleEarnings.vue'
import BeatDownloads from '#/components/charts/BeatDownloads.vue'
import BeatPlays from '#/components/charts/BeatPlays.vue'
import PageViews from '#/components/charts/PageViews.vue'
import Top10Beats from '#/components/charts/Top10Beats.vue'
import TileAnalytics from '#/components/TileAnalytics.vue'
import TasksProgress from '#/components/TasksProgress.vue'
import {
	fetchDetailedBeatDemoDownloads,
	fetchDetailedBeatPlays,
	fetchDetailedBeatSold,
	fetchDetailedPageVisits,
	fetchDetailedSales,
	fetchTopTenBeats,
	fetchTotalBeatDemoDownloads,
	fetchTotalBeatPlays,
	fetchTotalBeatSold,
	fetchTotalPageVisits,
	fetchTotalSales
} from '#/api/analytics'

@Component({
	components: {
		VLoadSpinner,
		TasksProgress,
		Top10Beats,
		BeatDownloads,
		BeatPlays,
		PageViews,
		SaleEarnings,
		TileAnalytics
	}
})
export default class DashboardPage extends Mixins(Dropdowns) {
	analyticsColors = analyticsColors
	isLoading = true
	periods = [
		{
			id: 'last30days',
			showText: 'Last 30 Days'
		},
		{
			id: 'last12months',
			showText: 'Last 12 Months'
		}
	]
	selectedPeriod = this.periods[0]
	totalSales: IAnalyticsDataTotal = null
	beatSold: IAnalyticsDataTotal = null
	beatDemoDownloads: IAnalyticsDataTotal = null
	beatPlays: IAnalyticsDataTotal = null
	pageVisits: IAnalyticsDataTotal = null
	// details
	detailedSales: IAnalyticsDataDetailed = null
	detailedBeatSold: IAnalyticsDataDetailed = null
	detailedBeatPlays: IAnalyticsDataDetailed = null
	detailedBeatDemoDownloads: IAnalyticsDataDetailed = null
	detailedPageVisits: IAnalyticsDataDetailed = null
	// top items chart
	topTenBeats: IAnalyticsDataTotal = null

	@UserStore.State('authenticatedUser') authenticatedUser: boolean

	setPeriod(periodIndex: number) {
		// if the upload was an image, we simply match the ids
		this.closeMenuDropdown()
		this.selectedPeriod = this.periods[periodIndex]
	}

	async loadAnalyticsData() {
		this.isLoading = true
		try {
			this.totalSales = await fetchTotalSales()
			this.beatSold = await fetchTotalBeatSold()
			this.beatDemoDownloads = await fetchTotalBeatDemoDownloads()
			this.beatPlays = await fetchTotalBeatPlays()
			this.pageVisits = await fetchTotalPageVisits()
			this.detailedSales = await fetchDetailedSales()
			this.detailedBeatSold = await fetchDetailedBeatSold()
			this.detailedBeatPlays = await fetchDetailedBeatPlays()
			this.detailedBeatDemoDownloads = await fetchDetailedBeatDemoDownloads()
			this.detailedPageVisits = await fetchDetailedPageVisits()
			this.topTenBeats = await fetchTopTenBeats()
		} catch (error) {
			console.error(error)
		} finally {
			this.isLoading = false
		}
	}

	async mounted() {
		this.loadAnalyticsData()
		// TODO: needed for the "tasks", to see if the producer uploaded beats
		// this.$store.dispatch('beat/fetchProducerBeats', { limit: 1, offset: 0 })
	}
}
