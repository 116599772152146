



import { Component, Mixins, Prop } from 'vue-property-decorator'
import { getDefaultChartOptions } from '#/const/charts'
import { ChartMethods } from '#/mixins/chart-methods'
import { analyticsColors } from '#/const/analytics-colors'
import { Chart } from 'chart.js'

@Component
export default class Top10Beats extends Mixins(ChartMethods) {
	@Prop() chartPoints: any[]

	get labels() {
		return this.chartPoints.map((item) => item.name)
	}

	getChartId() {
		return 'top10Beats'
	}

	mounted() {
		const chartOptions = getDefaultChartOptions()
		// @ts-ignore
		chartOptions.scales.y = { ticks: { mirror: true } }
		// @ts-ignore
		chartOptions.tooltips.callbacks.label = (tooltipItem, data) => {
			const quantity = data['datasets'][0]['data'][tooltipItem['index']]
			return `${quantity} play${quantity > 1 ? 's' : ''}`
		}
		chartOptions.indexAxis = 'y'
		const ctx = (document.getElementById(this.getChartId()) as HTMLCanvasElement).getContext('2d')!

		if (!this.chart)
			this.chart = new Chart(ctx, {
				type: 'bar',
				// @ts-ignore
				options: chartOptions,
				data: {
					datasets: []
				}
			})

		this.chart.data = {
			labels: this.labels,
			datasets: [
				{
					data: this.chartPoints.map((item) => item.plays),
					...this.generateChartStyling({ hex: analyticsColors.beatPlays.hex, colorRgb: analyticsColors.beatPlays.rgb })
				}
			]
		}

		this.chart.update()
	}
}
